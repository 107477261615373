import SearchFilterFacets from './SearchFilterFacets';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useSearch } from '../Search';
import { styled } from '@stitches/react';
import { FILTER_URL_CONSTANTS } from 'Shared/Common/Helpers';

type PropTypes = {
  categoryCode?: string;
};

function SearchFilterFacetsModalContainer({ categoryCode }: PropTypes) {
  const {
    staticPages: { searchPage },
  } = useAppSettingsData();
  const { result, facets } = useSearch(
    searchPage,
    FILTER_URL_CONSTANTS.DEFAULT_ITEMS,
    categoryCode
  );

  if (!facets) return null;

  return (
    <Wrapper>
      {result && result.facets && (
        <SearchFilterFacets facets={result.facets} modalMode={true} />
      )}
    </Wrapper>
  );
}

export default SearchFilterFacetsModalContainer;

const Wrapper = styled('div', {
  padding: '48px 24px 0 24px',
});
